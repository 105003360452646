const slideProduct = [
  {
    products: [
      {
        title: "Speaker",
        url: "images/slideproduct/pro1.png",
        items: 6,
      },
      {
        title: "Desktop & Laptop",
        url: "images/slideproduct/pro2.png",
        items: 6,
      },
      {
        title: "DSLR Camera",
        url: "images/slideproduct/pro3.png",
        items: 6,
      },
    ],
  },
  {
    products: [
      {
        title: "Speaker",
        url: "images/slideproduct/pro1.png",
        items: 6,
      },
      {
        title: "Desktop & Laptop",
        url: "images/slideproduct/pro2.png",
        items: 6,
      },
      {
        title: "DSLR Camera",
        url: "images/slideproduct/pro3.png",
        items: 6,
      },
    ],
  },
  {
    products: [
      {
        title: "Speaker",
        url: "images/slideproduct/pro1.png",
        items: 6,
      },
      {
        title: "Desktop & Laptop",
        url: "images/slideproduct/pro2.png",
        items: 6,
      },
      {
        title: "DSLR Camera",
        url: "images/slideproduct/pro3.png",
        items: 6,
      },
    ],
  },
];

export default slideProduct;
